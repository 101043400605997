
import React from 'react';
import { Button, Fade, Card, Alert } from 'react-bootstrap';
import LabeledIcon from '../common/LabeledIcon';
import './ErrorDetailModalContent.scss'
import { FormattedMessage } from 'react-intl';

export default class ErrorDetailModalContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick() {
        this.setState({ open: !this.state.open });
    }

    render() {
        const icon = this.state.open ? 'fa-minus' : 'fa-plus'
        return (
            <div className="error-detail-modal-content">
                <Alert variant="danger">{(this.props.error && this.props.error.summary) || <FormattedMessage id="app.common.generalError" />}</Alert>
                {this.props.error && this.props.error.detail &&
                    <>
                        < LabeledIcon
                            label={'Detail'}
                            icon={'fa fa-small fa-width ' + icon}
                            onClick={this.handleOnClick}
                        />
                        <Fade in={this.state.open}>
                            <div className="mt-2">
                                <Alert variant="secondary">{this.props.error.detail}</Alert>
                            </div>
                        </Fade>
                    </>
                }
            </div>
        );
    }
}