import React from 'react';
import { Link } from 'react-router-dom';

export default class Logo extends React.Component {
  render(){
    return (
        <Link to="/">
            <div className="app-logo flex-align-center">
                <div className="app-logo-img">
                    <img src="./assets/images/logo.png" alt="Application Logo"/>
                </div>
            </div>
        </Link>
    );
  }
}