export default {
  rootReducer: {

  },
  loading: 0,
  popup: {
    visible: false,
    data: '',
    title: '',
    className: '',
    width: '',
    callback: ''
  },
  notification: {
    visible: false,
    message: '',
    body: undefined,
    type: '',
    title: '',
    callback: undefined
  },
  reasonModal: {
    visible: false,
    callback: undefined,
    formType: undefined
  }
};