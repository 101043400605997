import {Action} from "../common/ActionType";
import initialState from "./initialState";

export default function popupReducer(state = initialState.popup, action) {
  switch (action.type) {
    case Action.ACTION_SHOW_POPUP:    
      return {visible: true, data: action.data, title: action.title, 
        width: action.width, className: action.className, callback: action.callback};
    case Action.ACTION_HIDE_POPUP:
      return {visible: false};
    default:
      return state;
  }
}
