import {
  AssetService
} from './AssetService'
import {
  underscorize,
  underscorizeObj
} from '../../../common/FormUtils';
const FormFieldDefinitions = require('../../../constants/form-field-definitions.json');

export const searchAsset = (queryObj) => {
  return AssetService.searchAsset(queryObj).then(response => {

    let res = undefined;
    if (response && response.data) {
      res = {
        totalSize: response.data.totalSize
      }
      if (response.data.records) {
        let id = 0;
        const assetList = response.data.records.map(record => {
          return {
            id: id++, //dummy
            name: record.Name,
            serialNumber: record.Name, //TODO: check and change to SerialNumber
            siteNumber: record.Site_Number__c,
            marketingName: record.Marketing_Name__c,
            companyName: (record.Service_Account__r && record.Service_Account__r.Name) || "",
            companyCity: record.Service_Account_City__c,
            identifyingProduct: record.Identifying_Product__c,
            status: record.Status
          }
        })
        res.records = assetList;
      }
      return res;
    }
  });
};

export const getKeys = (asset) => {

  const queryObj = {}

  queryObj['instance'] = asset[FormFieldDefinitions.instance.id]
  // queryObj['product_name'] = asset[FormFieldDefinitions.productName.id]
  queryObj['company_name'] = asset[FormFieldDefinitions.companyName.id]
  queryObj['serial_number'] = asset[FormFieldDefinitions.serialNumber.id]
  queryObj['site_number'] = asset[FormFieldDefinitions.siteNumber.id]
  queryObj['marketing_name'] = asset[FormFieldDefinitions.marketingName.id]

  return AssetService.getKeys(queryObj).then(response => {
    let res = undefined;
    if (response && response.data) {
      res = {}
      if (response.data && response.data.data && response.data.data.Items) {
        res.records = response.data.data.Items
        res.records.map(key => key.id = key.version)
      }

    }
    return res;
  })
}

export const generatekey = (asset) => {

  return AssetService.generateKey(underscorizeObj(asset)).then(response => {
    return (response && response.data) || {}

  });
};

export const activateKey = (asset) => {
  return AssetService.activateKey(underscorizeObj(asset)).then((response) => {
    return (response && response.data) || {}
  });
};

export const getPrivateKeyPackage = (asset) => {
  return AssetService.getPrivateKeyPackage(underscorizeObj(asset)).then(response => {
    download(response, true);
  });
};
const download = (response, decode) => {
  if (response) {
    const reader = new FileReader();
    reader.addEventListener('loadend', (e) => {
      const text = e.srcElement.result;
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;' + ((decode) ? 'base64,' : 'charset=utf-8,') + text);
      element.setAttribute('download', response.fileName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
    reader.readAsText(response.data);
  }
}

export const getPublicKeyPackage = (asset) => {
  return AssetService.getPublicKeyPackage(underscorizeObj(asset)).then(response => {
    download(response, false);
  });
};


export const retireKey = (asset) => {
  return AssetService.retireKey(underscorizeObj(asset)).then(response => {
    return (response && response.data) || {}
  });
};

export const unretirekey = (asset) => {
  return AssetService.unretirekey(underscorizeObj(asset)).then(response => {
    return (response && response.data) || {}
  });
};