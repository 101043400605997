
import { Action } from "../common/ActionType";
import intialState from './initialState';

export default function rootReducer(initialState, action) {
  if (action.type == Action.ACTION_DENY_ERROR) {
    try{            
      sessionStorage.clear();      
    }catch{}
    window.location = '/ReLogin';
  }
  return intialState.rootReducer;
}