import React from 'react';
import LabeledIcon from './../common/LabeledIcon';
import PropTypes from 'prop-types';
import { VaultRights, securityRight } from '../../common/SecurityRight';
const FormFieldDefinitions = require('../../constants/form-field-definitions.json');
export default class ProfileDropdown extends React.Component {
    static propType = {
        onOptionSelected: PropTypes.func
    };
    handleDropdownItemClick(option) {
        this.props.onOptionSelected(option);
    }
    render() {
        const securityRights = securityRight((this.props.userInfo && this.props.userInfo.userRights) || undefined);

        const showActivityLog = FormFieldDefinitions.instance.dropdowns.find(
            instanceType => securityRights.canAccess(instanceType.value) &&
                securityRights.can(instanceType.value, VaultRights.AuditLog))

        return (
            <div className="profile-dropdown">
                <div className="profile-dropdown-inner">
                    <div className="profile-dropdown-item cursor-ptr">
                        <LabeledIcon
                            label={'Logout'}
                            icon={'fa fa-small fa-width fa-sign-out text-gray-400'}
                            onClick={_ => this.handleDropdownItemClick('LOGOUT')}
                        />
                        {showActivityLog &&
                            <LabeledIcon
                                label={'Activity Logs'}
                                icon={'fa fa-small fa-width ffa fa-id-card-o text-gray-400'}
                                onClick={_ => this.handleDropdownItemClick('ACTIVITY_LOG')}
                            />
                        }
                        <LabeledIcon
                            label={'Help'}
                            icon={'fa fa-small fa-width fa-question-circle text-gray-400'}
                            onClick={_ => this.handleDropdownItemClick('HELP')}
                        />
                    </div>
                </div>
            </div>
        );
    }
}