import React from 'react';

import axios from "axios";
import store from './Store';
import {
    Action
} from './ActionType';
import {
    UserService
} from './UserService'
import {
    FormattedMessage
} from "react-intl";


export const HttpMethod = {
    POST: 'post',
    GET: 'get',
    DELETE: 'delete',
}

const Config = require('../../config/config_' + ((process.env.REACT_APP_STAGE) ? process.env.REACT_APP_STAGE : 'prod') + '.json');


export const makeDownloadRequest = async (requestURL, method, body, loading = true) => {
    const response = await sendRequest(requestURL, method, body, 'blob', loading);

    if (response) {
        let fileName = "default.txt";
        if (response.headers['content-disposition']) {
            fileName = response.headers['content-disposition'].split('filename=')[1];
            fileName = fileName.replace(/"/g, '');
        }
        return {
            success: true,
            data: response.data,
            fileName: fileName
        };
    }
}

export const makeRequest = async (requestURL, method = "get", data, requestType = "json",
    loading = true, responseErrorHandle) => {
    return await sendRequest(requestURL, method, data, requestType, loading);

}

const sendRequest = async (requestURL, method = "get", data, requestType = "json", loading) => {
    if (loading) {
        store.dispatch({
            type: Action.ACTION_LOADING
        });
    }
    return await axios({
        method: method,
        url: Config.Amplify.API.endpoints[0].endpoint + requestURL,
        responseType: requestType,
        withCredentials: false,
        headers: {
            'Authorization': UserService.getToken()
        },
        data: data
    }).catch(res => {
        if (res.response.status == 403) {
            store.dispatch({
                type: Action.ACTION_DENY_ERROR, //TOKEN EXPIRED
                error: {
                    detail: res.response.data && res.response.data.message ? res.response.data.message : res.response.data
                }
            });
        } else {
            store.dispatch({
                type: Action.ACTION_SHOW_ERROR_DETAIL,
                buttons: [{
                    text: 'OK',
                    isPrimary: true
                }],
                error: {
                    detail: res.response.data && res.response.data.message ? res.response.data.message : res.response.data
                }
            });
        }

    }).finally(() => {
        if (loading) {
            store.dispatch({
                type: Action.ACTION_LOADED
            });
        }
    });

}


export const httpGet = async (url, reqData, reqType = "json", loading = true,
        responseErrorHandler) =>
    await makeRequest(url, HttpMethod.GET, reqData, reqType, loading, responseErrorHandler);

export const httpPost = async (url, reqData, reqType = "json", loading = true,
        responseErrorHandler) =>
    await makeRequest(url, HttpMethod.POST, reqData, reqType, loading, responseErrorHandler);

export const httpDelete = async (url, reqData, reqType = "json", loading = true,
        responseErrorHandler) =>
    await makeRequest(url, HttpMethod.DELETE, reqData, reqType, loading, responseErrorHandler);