import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { injectIntl, FormattedMessage } from 'react-intl';
import React from 'react';
import { Link } from 'react-router-dom';

class AssetSearchResult extends React.Component {

    serialNumberFormatter = (cell, row) => {
        if (row.marketingName && row.identifyingProduct) {
            return <Link
                to={{
                    pathname: "/Details",
                    state: { data: { ...row, instance: this.props.instance } }
                }}
            >
                {cell}
            </Link>
        } else {
            return <span>{cell}</span>
        }

    }
    render() {
        const { formatMessage } = this.props.intl;

        const columns = [{
            dataField: 'id',
            text: 'Asset ID',
            hidden: true,
            isDummyField: true
        }, {
            dataField: 'serialNumber',
            text: <FormattedMessage id="app.asset.table.column.title.serialNumber" />,
            formatter: this.serialNumberFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: '110px' }
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.asset.table.column.tooltip.serialNumber" }) }
        }, {
            dataField: 'siteNumber',
            text: <FormattedMessage id="app.asset.table.column.title.siteNumber" />,
            headerStyle: (colum, colIndex) => {
                return { width: '100px' }
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.asset.table.column.tooltip.siteNumber" }) }

        }, {
            dataField: 'marketingName',
            text: <FormattedMessage id="app.asset.table.column.title.marketingName" />,
            headerStyle: (colum, colIndex) => {
                return { width: '200px' }
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.asset.table.column.tooltip.marketingName" }) }
        }, {
            dataField: 'companyName',
            text: <FormattedMessage id="app.asset.table.column.title.serviceAccount" />,
            headerStyle: (colum, colIndex) => {
                return { width: '200px' }
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.asset.table.column.tooltip.serviceAccount" }) }
        }, {
            dataField: 'companyCity',
            text: <FormattedMessage id="app.asset.table.column.title.serviceAccountCity" />,
            headerStyle: (colum, colIndex) => {
                return { width: '130px' }
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.asset.table.column.tooltip.serviceAccountCity" }) }
        }, {
            dataField: 'status',
            text: <FormattedMessage id="app.asset.table.column.title.status" />,
            headerStyle: (colum, colIndex) => {
                return { width: '75px' }
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.asset.table.column.tooltip.status" }) }
        }

        ];

        const options = {
            page: 1,
            sizePerPageList: [{
                text: '5', value: 5
            }, {
                text: '10', value: 10
            }, {
                text: 'All', value: this.props.data.records.length
            }],
            sizePerPage: 5,
            pageStartIndex: 1,
            paginationSize: 3,
            prePage: 'Prev',
            nextPage: 'Next',
            firstPage: 'First',
            lastPage: 'Last',
            paginationPosition: 'top'

        };

        return (
            <>
                <h4>Search Results</h4>
                <div className=" search-result">

                    <BootstrapTable
                        striped
                        hover
                        keyField='id'
                        columns={columns}
                        data={this.props.data.records}
                        headerClasses="thead-dark"
                        noDataIndication={(<FormattedMessage id='app.grid.noRecordsFound' />)}
                    // pagination={paginationFactory(options)}
                    />
                </div>
            </>
        );


    }
}
export default injectIntl(AssetSearchResult);