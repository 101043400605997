import React from 'react';
import PropTypes from 'prop-types';
import FormBase from '../common/FormBase';
import { initForm, getFormField } from '../../common/FormUtils';
import { Button } from 'react-bootstrap';
import * as Actions from './ActivityLogAction'
import { Action } from '../../common/ActionType';
import { connect } from "react-redux";
import Moment from 'moment';
import { VaultRights, securityRight } from '../../common/SecurityRight';

const ActivityLogForm = "ActivityLogForm";
const FormFieldDefinitions = require('../../constants/form-field-definitions.json');


class ActivityLogSearchForm extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      asset: {},
      activityLogs: [],
      forms: { ActivityLogForm: this.initializeForm() },
      formType: ActivityLogForm

    };
    this.handleOnInput = this.handleOnInput.bind(this)
    this.searchMore = this.searchMore.bind(this)
  }

  initializeForm = () => {

    //Merge all reasons of all buttons Generate, Download..
    const reasonFieldSpec = { ...FormFieldDefinitions.activityReason }
    // reasonFieldSpec.containerClassName = "col-xs-12 col-md-12"
    // reasonFieldSpec.labelClassName = "col-xs-12 col-md-2"
    // reasonFieldSpec.inputClassName = "col-xs-12 col-md-4"
    let reasonOptions = FormFieldDefinitions.generateKeyReason.dropdowns.concat(
      FormFieldDefinitions.downloadKeyReason.dropdowns,
      FormFieldDefinitions.activateKeyReason.dropdowns,
      FormFieldDefinitions.retireKeyReason.dropdowns,
      FormFieldDefinitions.unRetireKeyReason.dropdowns,
    )
    //Remove dupplication
    reasonFieldSpec.dropdowns = [...new Map(reasonOptions.map(item =>
      [item['name'], item])).values()];

    //Set default value for date
    const endDateFieldSpec = { ...FormFieldDefinitions.endDate }

    const now = new Date();
    endDateFieldSpec.value = now;

    const startDateFieldSpec = { ...FormFieldDefinitions.startDate }
    startDateFieldSpec.value = new Date().setDate(now.getDate() - 7);

    const serialNumberFieldSpec = { ...FormFieldDefinitions.serialNumber }
    serialNumberFieldSpec.required = false;
    const activityForm = [
      FormFieldDefinitions.marketingName,
      FormFieldDefinitions.instance,
      FormFieldDefinitions.companyName,
      serialNumberFieldSpec,
      FormFieldDefinitions.siteNumber,
      FormFieldDefinitions.userId,
      FormFieldDefinitions.eventType,
      reasonFieldSpec,
      startDateFieldSpec,
      endDateFieldSpec,
    ]
    return initForm(activityForm);

  }

  updateForm = (form, data) => {

    //Update new values received from props for the form components.
    Object.keys(data).forEach(key => {
      if (key !== FormFieldDefinitions.userId.id) {
        const formField = form.find(f => f.id === key)
        if (formField) {
          formField.value = data[key];
        }
      }
    })


    const securityRights = securityRight((data && data.userRights) || undefined);
    const instanceField = form.find(field => field.id === FormFieldDefinitions.instance.id);

    for (var i = instanceField.dropdowns.length - 1; i >= 0; i--) {
      const option = instanceField.dropdowns[i];
      if (!securityRights.canAccess(option.value)) {
        instanceField.dropdowns.splice(i, 1);
      }
    }
    //Locks form controls
    const lockFields = [
     //FormFieldDefinitions.marketingName,
      instanceField,
      FormFieldDefinitions.companyName,
      FormFieldDefinitions.serialNumber,
      FormFieldDefinitions.siteNumber
    ]


    lockFields.forEach(lf => {
      const lockField = form.find(f => f.id === lf.id)
      lockField.disabled = !securityRights.can(instanceField.value, VaultRights.AuditLog);;
    })


    return form;

  }


  componentDidUpdate = (prevProps) => {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      const forms = { ...this.state.forms }
      this.updateForm(forms[this.state.formType], this.props.data)
      this.setState({ forms: forms })
    }
  }


  handleOnInput = (event) => {
    event.formType = this.state.formType;
    const forms = { ...this.state.forms }
    let field = forms[event.formType].find(input => input.id === event.target.name);
    if (field) {
      field.value = event.target.value
      this.setState({ forms: forms })
    }
  }

  handleReset = (e) => {

    const form = this.initializeForm();

    this.updateForm(form, this.props.data)

    this.setState({ forms: { [this.state.formType]: form } })
    if (this.props.onReset) {
      this.props.onReset(e);
    }
  }

  handleSearch = (e) => {
    const activityLogForm = this.state.forms[this.state.formType];
    let searchCriteria = {};//reset
    activityLogForm.forEach(field => {
      if (field.value) {
        //if the field is date type, then format the date and change some field names
        switch (field.id) {
          case FormFieldDefinitions.startDate.id:
          case FormFieldDefinitions.endDate.id:
            searchCriteria[field.id] = field.value ? Moment(field.value).format("YYYY-MM-DD").toString() : field.value;
            break;
          case FormFieldDefinitions.activityReason.id:
            searchCriteria['reason'] = field.value;
            break;
          case FormFieldDefinitions.userId.id:
            searchCriteria['userId'] = field.value;
            break;
          default:
            searchCriteria[field.id] = field.value;
            break;
        }
      }

    })
    //user id and serial number
    // searchCriteria.serialNumber = this.props.data.serialNumber
    // searchCriteria.marketingName = this.props.data.marketingName
    if (this.validateSearchCriteria(searchCriteria)) {
      this.setState({ searchData: undefined, searchCriteria: searchCriteria })
      Actions.searchActivityLog(searchCriteria).then(res => {
        this.setState({ searchData: res })
        if (this.props.onSearch) {
          this.props.onSearch(e, res);
        }
      })
    }
  }
  validateSearchCriteria = (searchCriteria) => {
    const validationMessages = [];
    this.state.forms[this.state.formType].forEach(fieldSpec => {
      if (fieldSpec.required && !searchCriteria[fieldSpec.id]) {
        validationMessages.push(fieldSpec.label.concat(" is mandatory."))
      }
    })

    if (searchCriteria.startDate && searchCriteria.endDate
      && Moment(searchCriteria.startDate) > Moment(searchCriteria.endDate)) {
      validationMessages.push("Start date must be after end date.")
    }
    if (validationMessages.length > 0) {
      this.props.dispatch({
        type: Action.ACTION_SHOW_ERROR,
        buttons: [{ text: 'OK', isPrimary: true }],
        message: (<div className="display-linebreak">{validationMessages.join("\r\n")}</div>)
      });
      return false;
    }
    return true;
  }

  searchMore = () => {

    this.setState({ searchData: undefined, searchCriteria: searchCriteria })
    const searchCriteria = { ...this.state.searchCriteria }
    searchCriteria.lastEvaluatedKey = this.state.searchData.lastEvaluatedKey;
    this.setState({ searchData: undefined, searchCriteria: searchCriteria })

    Actions.searchActivityLog(searchCriteria).then(res => {
      this.setState({ searchData: res })
      if (this.props.onSearchMore) {
        this.props.onSearchMore(res);
      }
    })
  }

  render() {
    return (
      <div>
        <FormBase onInput={this.handleOnInput} {...this.props}
          formInputs={this.state.forms}
          formType={this.state.formType}
        />

        <div className="row justify-content-end search-btns pr-3">
          <div className="col-xs-12">
            <Button className={"btn btn-secondary"} onClick={this.handleReset}>Reset</Button>
            <Button className="btn btn-primary" onClick={this.handleSearch}>Search</Button>
          </div>
        </div>

      </div >
    );
  }
}

export default connect(null, null, null, { forwardRef: true })(ActivityLogSearchForm);