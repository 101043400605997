import React from 'react';
import GenericPage from '../../layout/GenericPage';
import AssetMainContent from './AssetMainContent';


export default class AssetPage extends React.Component {

    render() {
        return (
            <GenericPage render={() => <AssetMainContent  {...this.props}/>} pageTitle="Asset"/>
        );
    }
}