import React from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import { Action } from "../../common/ActionType";
// import { getMsgById, getMsgByIds } from "../../common/services/commonService";
class NotificationDialog extends React.Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  onClick = (e, btnTxt) => {
    this.props.dispatch({ type: Action.ACTION_DIALOG_HIDE });
    const buttons = this.buildButtons(this.props)
    for (var i = 0; i < buttons.length; i++) {
      const btn = buttons[i];
      if (btnTxt === btn.text) {
        this.doCallback(btn.callback)
        break;
      }
    }
  }
  handleClose = () => {
    this.props.dispatch({ type: Action.ACTION_DIALOG_HIDE });
    if (this.props.onClose) {
      this.doCallback(this.props.onClose)
    } else if (!this.props.buttons || this.props.buttons.length === 0) {
      //TRICKY: if there is no button and onClose provided, and default OK is created, 
      //the close action will be same with hit the button. Support current behavior.

      this.doCallback(this.props.callback)
    }
  }

  doCallback = (callback) => {
    if (callback && typeof callback === 'function') {
      callback()
    } else if (callback) {
      this.props.dispatch({ type: callback });
    }
  }

  buildButtons = (props) => {
    let buttons = props.buttons || [];
    if (buttons.length === 0) {
      //if there is no buttons provided, then render default OK button
      buttons.push({ text: 'OK', isPrimary: true, callback: props.callback })
    }
    return buttons;
  }

  render() {
    if (!this.props.visible) {
      return '';
    }

    let buttons = this.buildButtons(this.props)
    let renderButtons = buttons.map((btn, index) => {
      const variant = btn.isPrimary ? 'primary' : 'secondary';
      return (
        <Button key={index}
          autoFocus={btn.isPrimary}
          variant={variant}
          onClick={(e) => this.onClick(e, btn.text)}> {btn.text} </Button>
      )
    });

    let alertVariant = this.props.type == 'ERROR' ? 'danger' : this.props.type.toLowerCase();

    return (
      <>
        <Modal
          show={true}
          onHide={this.handleClose}
          backdrop="static"
          keyboard={false}
          enforceFocus={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {this.props.message && <Alert variant={alertVariant}> {this.props.message}</Alert>}
            {this.props.body && this.props.body}
          </Modal.Body>
          <Modal.Footer>
            {renderButtons}
          </Modal.Footer>
        </Modal>
      </>


    );
  }
}

function mapStateToProps(state) {
  return {
    visible: state.notification.visible,
    message: state.notification.message,
    body: state.notification.body,
    callback: state.notification.callback,  // callback action type when the OK button is hit
    title: state.notification.title,
    type: state.notification.type,
    buttons: state.notification.buttons, // customized buttons in case not provide default OK button
    onClose: state.notification.onClose // callback when the Close button is hit.
  };
}

export default connect(
  mapStateToProps,
  null
)(NotificationDialog);
