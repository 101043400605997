import React from 'react';
import PropTypes from 'prop-types';
import './LabeledIcon.scss'
export default class LabeledIcon extends React.Component {

  static propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func
  };

  render() {
    return (
      <div className="iconText flex-align-center padding-all-1" onClick={this.props.onClick}>
        <div className="iconText-img">
          <i className={this.props.icon}></i>
        </div>
        <div className="iconText-text font-weight-400">
          {this.props.label}
        </div>
      </div>
    );
  }
}