import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { Action } from '../../common/ActionType';
import { connect } from "react-redux";
export default withOktaAuth(connect()(class Login extends React.Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
  }

  async login() {
    this.props.authService.login('/');
  }
  componentDidMount = () => {

    if (this.props.location && this.props.location.state && this.props.location.state.error) {
      this.props.dispatch({
        type: Action.ACTION_SHOW_ERROR_DETAIL,
        buttons: [{ text: 'OK', isPrimary: true }],
        error: { detail: this.props.location.state.error.message }
      });
    } else if (this.props.errorMessage) {
      this.props.dispatch({
        type: Action.ACTION_SHOW_ERROR,
        buttons: [{ text: 'OK', isPrimary: true }],
        message: this.props.errorMessage
      });
    }

  }

  render() {
    return (
      <div className="login-form" >
        <div className="login-card">
          <div className="login-card--logo bg-login-image flex-align-center justify-center">
            <div>
              <h1 className="h1 center-text m-0">Hitachi Vantara</h1>
              <h1 className="h4 center-text m-0">Secure Key Management</h1>
            </div>
          </div>
          <div className="login-card--form">
            <div className="p-3">
              <div className="login-card--form-title center-text">
                <h1 className="h4 text-gray-900 m-0 mb-4">Welcome</h1>
              </div>
              <div className="flex-align-center justify-center" >
                <button onClick={this.login} type="btn" className="justify-center btn btn-primary">Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}))