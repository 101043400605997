import {
    ActivityLogService
} from './ActivityLogService'
import {
    underscorizeObj
} from '../../common/FormUtils';
export const searchActivityLog = (searchCriteria) => {

    //convert to camel case: productName --> product_name
    const queryObj = underscorizeObj(searchCriteria);
    queryObj.LastEvaluatedKey = queryObj.last_evaluated_key;
    delete queryObj.last_evaluated_key;

    return ActivityLogService.searchEventLog(queryObj).then(response => {
        let res = undefined;
        if (response && response.data && response.data.data) {
            res = {}
            res.lastEvaluatedKey = response.data.data.LastEvaluatedKey;
            if (response.data.data.Items) {
                let cnt = 0;
                res.records = response.data.data.Items.map(key => {
                    return {
                        id: cnt++,
                        createdDate: key.timestamp,
                        event: key.event,
                        instance: key.instance,
                        productName: key.product_name,
                        companyName: key.company_name,
                        marketingName: key.marketing_name,
                        siteNumber: key.site_number,
                        serialNumber: key.serial_number,
                        version: key.version,
                        reason: key.reason,
                        reasonDetail: key.reason_detail,
                        userId: key.userID
                    }
                })

            }
        }
        return res;
    })
};