import {
    httpGet,
} from "./AjaxService";
import {
    ROLE
} from './Constants';

const SESSION_OKTA_TOKEN_STORAGE_KEY = 'okta-token-storage';
const SESSION_STORAGE_USER_INFO_KEY = 'user-info';

export class UserService { 
    static setAlert = (token_expires_in,authService) => {
        let first_alert= (token_expires_in-300)*1000;
        let second_alert= (token_expires_in)*1000;
        
        let alert1=sessionStorage.getItem('alert1');
        let alert2=sessionStorage.getItem('alert2');        
        if (alert1!=null){clearTimeout(alert1);}  
        if (alert2!=null){clearTimeout(alert2);}  
        
        let timer1;
        let timer2;
        timer1=setTimeout(() => {
            alert ('Due to security reasons your session will end in 5 minutes');
        }, first_alert);
        sessionStorage.setItem('alert1',timer1);
        timer2=setTimeout(async () => {
            alert ('You are logged out now');
            sessionStorage.clear();
            authService.logout('/Login');            
            //await Auth.signOut();            
        }, second_alert);
        sessionStorage.setItem('alert2',timer2);
    }
    
    
    static getToken = () => {
        const token = JSON.parse(sessionStorage.getItem(SESSION_OKTA_TOKEN_STORAGE_KEY));
        return token && token.idToken && token.idToken.value;
    }

    static getTokenClaims() {
        const token = JSON.parse(sessionStorage.getItem(SESSION_OKTA_TOKEN_STORAGE_KEY));
        return token && token.idToken && token.claims;
    }

    static getAccessToken() {
        const token = JSON.parse(sessionStorage.getItem(SESSION_OKTA_TOKEN_STORAGE_KEY));
        return token && token.idToken && token.accessToken;
    }

    static isSuperUser() {
        const loginInfo = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_USER_INFO_KEY));
        return loginInfo && loginInfo.userType === ROLE.InternalSuperAdminUser;
    }


    static async getLoginInfo(setTimer,authService) {            
        if(setTimer) {
            //SET TOKEN EXPIRE ALERTS 
            let timeNow=Math.round(Date.now()/1000);
            let token_expires_in= this.getAccessToken().expiresAt-timeNow;
            this.setAlert(token_expires_in,authService);
        }

        sessionStorage.removeItem(SESSION_STORAGE_USER_INFO_KEY)
        return await httpGet("/logininfo/", undefined, "json", false).then(response => {
            if (response && response.data) {
                sessionStorage.setItem(SESSION_STORAGE_USER_INFO_KEY, JSON.stringify(response.data));
                return response.data;
            }
            return undefined;
        })        
    }
}