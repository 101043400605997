import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FormattedMessage } from 'react-intl';


export default class AssetSSHKeyTable extends React.Component {


  render() {
    const columns = [{
      dataField: 'version',
      hidden: true
    },
    {
      dataField: 'version',
      text: <FormattedMessage id="app.ssh.version" />,
      headerStyle: (colum, colIndex) => {
        return { width: '50px' };
      }
    }, {
      dataField: 'status',
      text: <FormattedMessage id="app.ssh.status" />,
      headerStyle: (colum, colIndex) => {
        return { width: '75px' };
      }
    }, {
      dataField: 'reason',
      text: <FormattedMessage id="app.ssh.reasonForRotation" />,
      headerStyle: (colum, colIndex) => {
        return { width: '200px' };
      }
    }, {
      dataField: 'created_by',
      text: <FormattedMessage id="app.ssh.createdBy" />,
      headerStyle: (colum, colIndex) => {
        return { width: '120px' };
      }
    }, {
      dataField: 'created',
      text: <FormattedMessage id="app.ssh.createdDateTime" />,
      headerStyle: (colum, colIndex) => {
        return { width: '120px' };
      }
    }

    ];

    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      selected: [this.props.selectedKey && this.props.selectedKey.id || undefined],
      onSelect: (row, isSelect, rowIndex, e) => {
        this.props.onKeySelected(row);
      }
    };


    const options = {
      page: 1,
      sizePerPageList: [{
        text: '5', value: 5
      }, {
        text: '10', value: 10
      }, {
        text: 'All', value: this.props.data.records.length
      }],
      sizePerPage: 5,
      pageStartIndex: 1,
      paginationSize: 3,
      prePage: 'Prev',
      nextPage: 'Next',
      firstPage: 'First',
      lastPage: 'Last',
      paginationPosition: 'top'

    };

    return (
      <>
        <div className=" search-result">

          <BootstrapTable
            striped
            hover
            keyField='id'
            columns={columns}
            data={this.props.data.records}
            headerClasses="thead-dark"
            noDataIndication={(<FormattedMessage id='app.grid.noRecordsFound' />)}
            // pagination={paginationFactory(options)}
            selectRow={selectRow}
          />
        </div>
      </>
    );


  }
}