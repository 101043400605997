import { createStore, applyMiddleware, combineReducers } from "redux";
import rootReducer from '../reducers/rootReducer';
import popup from '../reducers/popupReducer';
import initialState from '../reducers/initialState';
import thunk from 'redux-thunk';
import loading from '../reducers/loadingReducer';
import notification from '../reducers/notificationReducer';
import reasonModal from '../reducers/reasonModalReducer';

function createReducer(asyncReducers) {
  return combineReducers({
    rootReducer,
    popup,
    notification,
    loading,
    reasonModal

  })


}


function configureStore(state) {

  let asyncReducers = {}
  let store = createStore(
    createReducer(),
    state,
    applyMiddleware(thunk)
  );

  // // Create an inject reducer function
  // // This function adds the async reducer, and creates a new combined reducer

  store.injectReducer = (key, asyncReducer) => {
    asyncReducers[key] = asyncReducer
    store.replaceReducer(createReducer(asyncReducers));

  }

  return store;
}






let store = configureStore(initialState);
export default store;