import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import ProfileDropdown from './ProfileDropdown';
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import { UserService } from '../../common/UserService';
const Roles = require('../../constants/roles.json');
const Config = require('../../../config/config_' + ((process.env.REACT_APP_STAGE) ? process.env.REACT_APP_STAGE : 'prod') + '.json');


export default withOktaAuth(withRouter(class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileDropdown: false,
      userInfo: {}
    };
    this.handleBodyClick = this.handleBodyClick.bind(this);
    this.handleProfileClick = this.handleProfileClick.bind(this);
    this.logout=this.logout.bind(this);
  }

  handleProfileClick(e) {
    //https://stackoverflow.com/questions/24415631/reactjs-syntheticevent-stoppropagation-only-works-with-react-events/24421834#24421834
    // e.preventDefault()
    e.stopPropagation();
    // e.nativeEvent.stopImmediatePropagation();

    this.setState({
      showProfileDropdown: !this.state.showProfileDropdown
    });
  }

  async logout() {
    //this.props.authService.logout(); //POST_LOGOUT_REDIRECT_URI not working    
    try{
      this.props.authService.clearAuthState();      
      sessionStorage.clear();
      //await this.props.authService.logout('/Login');   
      await Auth.signOut();   
    }catch{}    
    window.location.href = Config.Amplify.API.endpoints[1].endpoint;    
  }

  handleBodyClick(e) {
    this.setState({
      showProfileDropdown: false
    });
  }

  async handleDropDownSelection(option) {//Have to tie with Okta and Auth for token invalidation

    switch (option) {
      case 'LOGOUT':
        await this.logout();
        return;
      case 'ACTIVITY_LOG':
        this.props.history.push("/ActivityLog");
        return;
      case 'HELP':
        const userType = (this.state.userInfo && this.state.userInfo.userType) || undefined;
        const role = Object.values(Roles).find(role => role.ID === userType);
        let helpUrl = (role && role.HelpUrl) || Roles.InternalStandardUser.HelpUrl;
        window.open(helpUrl, 'sharer', 'toolbar=0,status=0,width=800,height=600');
        return;
    }

  }

  async componentDidMount() {
    window.addEventListener('click', this.handleBodyClick);
    this.props.authService.getUser().then((userInfo) => {
      UserService.getLoginInfo(true,this.props.authService).then(res => {
        this.setState({
          userInfo: { ...userInfo, ...res }
        })
      })

    });

  }

  render() {

    return (
      <div className="user-profile-main" >
        <div className="user-profile flex-align-center" id="profileContent" onClick={this.handleProfileClick}>

          <div className="user-profile-name padding-lr-med font-size-small font-dark">{this.state.userInfo.name}</div>
          <div className="user-profile-pic border-radius-50">
            <img className="full-width-img border-radius-50" src="./assets/images/profile-pic.jpg" alt="Profile" />
          </div>
        </div>

        {
          this.state.showProfileDropdown &&
          <div className="profile-dropdown-container shadow padding-tb-med text-left animation-grow" id="profileDropdown" >
            <ProfileDropdown
              onOptionSelected={option => this.handleDropDownSelection(option)}
              userInfo={this.state.userInfo}
            />
          </div>
        }
      </div>
    );
  }
}));