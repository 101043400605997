import React from 'react';
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Action } from "../../../common/ActionType";

import FormBase from '../../common/FormBase';
import { } from '../../../common/FormUtils';
import {
  FormFieldDefinitions, FormTypeGenerateKey, FormTypeDownloadKey,
  FormTypeActivateKey, FormTypeRetireKey, FormTypeUnRetireKey
} from './FormConstant';
import { FormattedMessage } from 'react-intl';

class ReasonModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      alertModalData: {},
      formType: '',
      forms: this.initReasonForms(),
    };
    this.handleOnInput = this.handleOnInput.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  initReasonForms = () => {
    const reasonDetailFieldSpec = this.initReasonDetailField({ ...FormFieldDefinitions.reasonDetail })
    const contClassName = "col-xs-12 col-lg-12";

    const generateKeyForm = [{ ...FormFieldDefinitions.generateKeyReason, containerClassName: contClassName },
      reasonDetailFieldSpec]
    const activateKeyForm = [{ ...FormFieldDefinitions.activateKeyReason, containerClassName: contClassName },
      reasonDetailFieldSpec]
    const downloadKeyForm = [{ ...FormFieldDefinitions.downloadKeyReason, containerClassName: contClassName },
      reasonDetailFieldSpec]
    const retiredKeyForm = [{ ...FormFieldDefinitions.retireKeyReason, containerClassName: contClassName },
      reasonDetailFieldSpec]
    const unRetiredKeyForm = [{ ...FormFieldDefinitions.unRetireKeyReason, containerClassName: contClassName },
      reasonDetailFieldSpec]

    const reasonForms = {
      [FormTypeGenerateKey]: generateKeyForm,
      [FormTypeDownloadKey]: downloadKeyForm,
      [FormTypeActivateKey]: activateKeyForm,
      [FormTypeRetireKey]: retiredKeyForm,
      [FormTypeUnRetireKey]: unRetiredKeyForm
    }
    return reasonForms;
  }

  initReasonDetailField = (reasonFieldSpec) => {
    reasonFieldSpec.containerClassName = "col-12"
    reasonFieldSpec.labelClassName = "col-12 text-uppercase"
    reasonFieldSpec.inputClassName = "col-12"
    return reasonFieldSpec;
    // return initForm([reasonFieldSpec])
  }
  handleOnInput = (event) => {
    event.formType = this.props.formType;
    const forms = { ...this.state.forms }
    let fieldSpec = forms[event.formType].find(input => input.id === event.target.name);

    let data = {};
    //Update value
    fieldSpec.value = event.target.value
    //Show/Hide Reason Detail field.
    let reasonDetailField = forms[event.formType].find(input => input.id === FormFieldDefinitions.reasonDetail.id);
    if (fieldSpec.id !== FormFieldDefinitions.reasonDetail.id) {
      const selectedOption = fieldSpec.dropdowns.find(dr => dr.value === fieldSpec.value);
      reasonDetailField.visible = (selectedOption && selectedOption.need_detail === true) || false;

      //reset reason_detail field if it is not presented
      if (reasonDetailField.visible === false) {
        reasonDetailField.value = undefined;
        data = { [reasonDetailField.id]: reasonDetailField.value }
      }
    }


    this.setState({ forms: forms })
    data[event.target.name] = event.target.value;
    this.props.onChange({ ...data });
  }

  handleCancel = (event) => {
    this.props.dispatch({ type: Action.ACTION_HIDE_REASON_MODAL });
  }

  handleOnClick = (event) => {
    if (this.validateForm()) {
      this.props.dispatch({ type: Action.ACTION_HIDE_REASON_MODAL });
      this.doCallback(this.props.callback)
    }
  }
  doCallback = (callback) => {
    if (callback && typeof callback === 'function') {
      callback()
    } else if (callback) {
      this.props.dispatch({ type: callback });
    }
  }
  handleClose = () => {
    this.props.dispatch({ type: Action.ACTION_HIDE_REASON_MODAL });
    if (this.props.onClose) {
      this.doCallback(this.props.onClose)
    }
  }

  validateForm = () => {
    const validationMessages = [];
    this.state.forms[this.props.formType].forEach(fieldSpec => {
      if (fieldSpec.visible !== false && fieldSpec.required && !fieldSpec.value) {
        validationMessages.push(fieldSpec.label.concat(" is mandatory."))
      }
    })
    //reason detail
    let reasonDetailField = this.state.forms[this.props.formType].find(input => input.id === FormFieldDefinitions.reasonDetail.id);
    if (reasonDetailField.visible && reasonDetailField.value && reasonDetailField.value.length < 3) {
      validationMessages.push(reasonDetailField.label.concat(" should have minimum 3 characters."));
    }

    if (validationMessages.length > 0) {
      this.props.dispatch({
        type: Action.ACTION_SHOW_ERROR,
        buttons: [{ text: 'OK', isPrimary: true }],
        message: (<div className="display-linebreak">{validationMessages.join("\r\n")}</div>)
      });
      return false;
    }
    return true;
  }

  render() {

    if (!this.props.visible) {
      return '';
    }


    let buttonText = undefined;

    switch (this.props.formType) {
      case FormTypeGenerateKey:
        buttonText = <FormattedMessage id="app.assetDetail.generate" />
        break;
      case FormTypeDownloadKey:
        buttonText = <FormattedMessage id="app.assetDetail.download" />
        break;
      case FormTypeActivateKey:
        buttonText = <FormattedMessage id="app.assetDetail.activate" />
        break;
      case FormTypeRetireKey:
        buttonText = <FormattedMessage id="app.assetDetail.retire" />
        break;
      case FormTypeUnRetireKey:
        buttonText = <FormattedMessage id="app.assetDetail.unRetire" />
        break;
    }
    return (

      <Modal
        show={true}
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title><FormattedMessage id='app.common.info' /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormBase
            onInput={this.handleOnInput}
            formType={this.props.formType}
            formInputs={this.state.forms}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            autoFocus={false}
            variant="secondary"
            onClick={this.handleCancel}> <FormattedMessage id='app.common.cancel' />
          </Button>

          <Button
            autoFocus={true}
            variant="primary"
            onClick={this.handleOnClick}> {buttonText}
          </Button>

        </Modal.Footer>
      </Modal>

    );

  }

}
function mapStateToProps(state) {
  return {
    visible: state.reasonModal.visible,
    callback: state.reasonModal.callback,
    formType: state.reasonModal.formType,
    type: state.reasonModal.type
  };
}

export default connect(
  mapStateToProps,
  null
)(ReasonModal);