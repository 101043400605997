import React from "react";
import { connect } from "react-redux";
import { Modal, Button } from 'react-bootstrap';
import { Action } from "../../common/ActionType";

class ReadOnlyPopup extends React.Component {
  togglePopup = content => {
    this.props.dispatch({ type: Action.ACTION_HIDE_POPUP });
    if (this.props.callback !== undefined) {
      this.props.dispatch({ type: this.props.callback });
    }
  };

  render() {
    if (!this.props.visible) {
      return '';
    }
    return (

      <Modal onHide={this.togglePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.togglePopup}>
            Close
        </Button>
          <Button variant="primary" onClick={this.togglePopup}>
            Save Changes
        </Button>
        </Modal.Footer>
      </Modal>
      // <Dialog
      //   className={this.props.className}
      //   width={this.props.width}
      //   title={this.props.title}
      //   onClose={this.togglePopup}
      // >
      //   {this.props.data}
      // </Dialog>
    );
  }
}
function mapStateToProps(state) {
  return {
    visible: state.popup.visible,
    title: state.popup.title,
    data: state.popup.data,
    className: state.popup.className,
    width: state.popup.width,
    callback: state.popup.callback,  // callback action type
  };
}

export default connect(
  mapStateToProps,
  null
)(ReadOnlyPopup);
