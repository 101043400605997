
import React from 'react';
import Button from 'react-bootstrap/Button';
import { VaultRights, securityRight } from '../../../common/SecurityRight';

export default class ActionButton extends React.Component {
    render() {



        const buttonVisibleMap = {}

        if (this.props.sshKey) {
            switch (this.props.sshKey.status) {
                case 'Generated':
                case 'Unretired':
                    buttonVisibleMap.showGenerate = true;
                    buttonVisibleMap.showActivate = true;
                    buttonVisibleMap.showDownload = true;
                    buttonVisibleMap.showRetired = true;
                    break;
                case 'Active':
                    buttonVisibleMap.showGenerate = true;
                    buttonVisibleMap.showDownload = true;
                    buttonVisibleMap.showRetired = true;
                    break;
                case 'Retired':
                    buttonVisibleMap.showGenerate = true;
                    buttonVisibleMap.showUnRetired = true;
                    break;
                default:
                    buttonVisibleMap.showGenerate = true;
                    break;
            }
        } else {
            buttonVisibleMap.showGenerate = true;
        }
        const instance = this.props.instance;
        const securityRights = securityRight((this.props.userInfo && this.props.userInfo.userRights) || undefined);

        buttonVisibleMap.showGenerate = buttonVisibleMap.showGenerate
            && securityRights.can(instance, VaultRights.GenerateKey);
        buttonVisibleMap.showActivate = buttonVisibleMap.showActivate
            && securityRights.can(instance, VaultRights.Activate);
        buttonVisibleMap.showDownload = buttonVisibleMap.showDownload
            && (securityRights.can(instance, VaultRights.DLPrivatePackage)
                || securityRights.can(instance, VaultRights.DLPublicPackage));
        buttonVisibleMap.showRetired = buttonVisibleMap.showRetired
            && securityRights.can(instance, VaultRights.Retire);
        buttonVisibleMap.showUnRetired = buttonVisibleMap.showUnRetired
            && securityRights.can(instance, VaultRights.Unretire);

        return (
            <div className="row justify-content-end search-btns pr-3">
                <div className="col-xs-12">
                    {buttonVisibleMap.showGenerate &&
                        <Button className="btn btn-primary" onClick={this.props.onGenerateClick}>GENERATE KEY</Button>}
                    {buttonVisibleMap.showActivate &&
                        <Button className="btn btn-primary" onClick={this.props.onActivateClick}>ACTIVATE</Button>}
                    {buttonVisibleMap.showDownload &&
                        <Button className="btn btn-primary" onClick={this.props.onDownloadClick}>DOWNLOAD</Button>}
                    {buttonVisibleMap.showRetired &&
                        <Button className="btn btn-primary" onClick={this.props.onRetireClick}>RETIRE</Button>}
                    {buttonVisibleMap.showUnRetired &&
                        <Button className="btn btn-primary" onClick={this.props.onUnRetireClick}>UNRETIRE</Button>}
                </div>
            </div>)
    }

}