import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import "./Loading.scss";
// Loading indicator
class Loading extends React.Component {
  render() {
    if (!this.props.isNotLoginScreen) {//Except login screen
      if (this.props.cnt === 0) {
        // this is no on-going loading, disable loading indicator
        return "";
      }
    }

    let opacity = 0.3;
    if (this.props.opacity) {
      // allow caller to change opacity
      opacity = this.props.opacity;
    }
    const backgroundColor = {
      backgroundColor: `rgba(128, 128, 128, ${opacity})`
    };

    const loadingPanel = (
      <div style={backgroundColor} className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    );
    let gridContent = document.querySelector(this.props.target);
    if (!gridContent) {
      gridContent = document.querySelector("#root");
    }
    return ReactDOM.createPortal(loadingPanel, gridContent);
  }
}
function mapStateToProps(state) {
  return {
    cnt: state.loading // total number of loading in progress
  };
}

export default connect(
  mapStateToProps,
  null
)(Loading);
