import { Action } from "../common/ActionType";
import initialState from "./initialState";


export default function reasonModalReducer(state = initialState.reasonModal, action) {

  switch (action.type) {

    case Action.ACTION_SHOW_REASON_MODAL:
      return { visible: true, callback: action.callback, formType: action.formType };

    case Action.ACTION_HIDE_REASON_MODAL:
      return { visible: false };

    default:
      return state;
  }
}
