import {
    httpPost
} from "../../common/AjaxService";



export class ActivityLogService {

    static async searchEventLog(queryObj) {
        return await httpPost("/searcheventlog", queryObj, "json", true);
    };



}