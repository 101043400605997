import React from 'react';
import GenericPage from '../layout/GenericPage';
import ActivityLogMain from './ActivityLogMain';

export default class ActivityLogPage extends React.Component {


    render() {

        return (
            <GenericPage render={() => <ActivityLogMain  {...this.props} />} pageTitle="Activity Logs" />

        );
    }
}