import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import AssetSearchForm from './AssetSearchForm'
import { initForm } from '../../../common/FormUtils';
import { securityRight } from '../../../common/SecurityRight';
import AssetSearchResult from './AssetSearchResult';
import * as Actions from './AssetAction'
import Button from 'react-bootstrap/Button';
import { Action } from '../../../common/ActionType';
import { connect } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { UserService } from '../../../common/UserService';

const FormFieldDefinitions = require('../../../constants/form-field-definitions.json');

const ASSET_FORM = "ASSET_FORM";

export default withOktaAuth(connect()(class AssetMainContent extends React.Component {
  constructor(props) {
    super(props);
    //Defined the form definitions for the page
    this.state = {
      formType: ASSET_FORM,
      forms: { ASSET_FORM: this.initAssetForm() },
      searchCriteria: {},
      searchData: undefined

    };

  }

  initAssetForm = () => {
    const assetForm = [
      FormFieldDefinitions.marketingName,
      FormFieldDefinitions.instance,
      FormFieldDefinitions.identifyingProduct,
      FormFieldDefinitions.serialNumber,
      FormFieldDefinitions.caseNumber,
      FormFieldDefinitions.siteNumber,
      FormFieldDefinitions.companyName,
    ]

    return initForm(assetForm);

  }

  updateForm = (form, data) => {
    const securityRights = securityRight((data && data.userRights) || undefined);
    const instanceField = form.find(field => field.id === FormFieldDefinitions.instance.id);

    for (var i = instanceField.dropdowns.length - 1; i >= 0; i--) {
      const option = instanceField.dropdowns[i];
      if (!securityRights.canAccess(option.value)) {
        instanceField.dropdowns.splice(i, 1);
      }
    }
    instanceField.disabled = instanceField.dropdowns.length == 1;

    return form;
  }


  //Have to implement RefreshToken() method for Amplify to use,
  //to refresh token with Okta, but Okta SDK doesnt provide an API to refresh token
  componentDidMount = () => {
    // let idToken = this.props.authState.idToken
    // let tokenClaims = parseJwt(idToken)
    UserService.getLoginInfo().then(res => {
      const forms = { ...this.state.forms }
      this.updateForm(forms[this.state.formType], res)
      this.setState({ userInfo: res, forms: forms })
    })

  }

  handleAssetFormChange = (event) => {
    //find the just updated form field
    const forms = { ...this.state.forms }
    let formInputDef = forms[event.formType].find(input => input.id === event.target.name);
    if (formInputDef) {
      formInputDef.value = event.target.value
      this.setState({ forms: forms })
    }

  }

  handleSearch = (e) => {
    const assetForm = this.state.forms[ASSET_FORM];
    const searchCriteria = {};
    assetForm.forEach(field => {
      if (field.value) {
        searchCriteria[field.id] = field.value;
      }
    })
    if (this.validateSearchCriteria(searchCriteria)) {
      this.setState({ searchData: undefined, searchCriteria: searchCriteria })
      Actions.searchAsset(searchCriteria).then(res => {
        this.setState({ searchData: res })
      })
    }
  }

  validateSearchCriteria = (searchCriteria) => {
    if (!searchCriteria[FormFieldDefinitions.serialNumber.id]) {
      this.props.dispatch({
        type: Action.ACTION_SHOW_ERROR,
        buttons: [{ text: 'OK', isPrimary: true }],
        message: <FormattedMessage id="app.home.serialNumber.required" />
      });
      return false;
    }
    return true;
  }

  handleReset = (e) => {
    this.setState({ forms: { ASSET_FORM: this.initAssetForm() }, searchData: undefined })

  }
  render() {

    return (
      <div className="col-12">
        <AssetSearchForm
          formInputs={this.state.forms}
          formType={ASSET_FORM}
          onChange={(event) => this.handleAssetFormChange(event)}
        />

        <div className="row justify-content-end search-btns pr-3">
          <div className="col-xs-12">
            <Button className={"btn btn-secondary"} onClick={this.handleReset}>Reset</Button>
            <Button className="btn btn-primary" onClick={this.handleSearch}>Search</Button>
          </div>
        </div>


        {this.state.searchData && this.state.searchData.records &&
          <AssetSearchResult data={this.state.searchData} {...this.state.searchCriteria} />
        }
      </div>

    );
  }
}))
