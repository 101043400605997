import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import './CustomLabel.scss'


export default function CustomLabel(props) {


    let render = undefined;
    if (props.tooltip) {

        render = <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip hide='false' className="custom-tooltip">{props.tooltip}</Tooltip>}
        >
            <label className={props.labelClassName} htmlFor={props.id} >{props.label}</label>
        </OverlayTrigger>
    } else {
        render = <label className={props.labelClassName} htmlFor={props.id} >{props.label}</label>
    }

    return render


}

