import React from 'react';
import { Action } from "../common/ActionType";
import initialState from "./initialState";
import ErrorDetailModalContent from "../components/error/ErrorDetailModalContent"


export default function notificationReducer(
  state = initialState.notification,
  action
) {
  const actionObject = {
    visible: true,
    message: action.message,
    callback: action.callback,
    onClose: action.onClose,
    body: action.body,
    // noCallbackOnCloseButton: action.noCallbackOnCloseButton,
    // actionButtonText: action.actionButtonText,
    // secondaryButtonText: action.secondaryButtonText,
    // secondaryButtonClickCallback: action.secondaryButtonClickCallback,
    buttons: action.buttons
  }
  switch (action.type) {

    case Action.ACTION_SHOW_ERROR:
      return Object.assign(actionObject, {
        title: action.title ? action.title : "Error",
        type: "ERROR"
      });
    case Action.ACTION_SHOW_ERROR_DETAIL:
      return Object.assign(actionObject, {
        title: action.title ? action.title : "Error",
        type: "ERROR",
        body: <ErrorDetailModalContent error={action.error} />
      });
    case Action.ACTION_SHOW_WARN:
      return Object.assign(actionObject, {
        title: action.title ? action.title : "Warning",
        type: "WARNING"
      });
    case Action.ACTION_SHOW_INFO:
      return Object.assign(actionObject, {
        title: action.title ? action.title : "Info",
        type: "INFO"
      });
    case Action.ACTION_DIALOG_HIDE:
      return { visible: false };

    default:
      return state;
  }
}
