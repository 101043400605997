import {Action} from "../common/ActionType";
import initialState from "./initialState";

export default function loadingReducer(state = initialState.loading, action) {
  switch (action.type) {
    case Action.ACTION_LOADING:
      return (state + 1);
    case Action.ACTION_LOADED:
      return (state - 1);
    default:
      return state;
  }
}
