import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { injectIntl, FormattedMessage } from 'react-intl';
import React from 'react';
class AssetTable extends React.Component {

    render() {
        const { formatMessage } = this.props.intl;
        const columns = [{
            dataField: 'id',
            text: 'Asset ID',
            hidden: true,
            isDummyField: true
        }, {
            dataField: 'name',
            text: <FormattedMessage id="app.asset.table.column.title.serialNumber" />,
            headerStyle: (colum, colIndex) => {
                return { width: '110px' }
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.asset.table.column.tooltip.serialNumber" }) }

        }, {
            dataField: 'siteNumber',
            text: <FormattedMessage id="app.asset.table.column.title.siteNumber" />,
            headerStyle: (colum, colIndex) => {
                return { width: '100px' }
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.asset.table.column.tooltip.siteNumber" }) }

        }, {
            dataField: 'marketingName',
            text: <FormattedMessage id="app.asset.table.column.title.marketingName" />,
            headerStyle: (colum, colIndex) => {
                return { width: '200px' }
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.asset.table.column.tooltip.marketingName" }) }

        }, {
            dataField: 'companyName',
            text: <FormattedMessage id="app.asset.table.column.title.serviceAccount" />,
            headerStyle: (colum, colIndex) => {
                return { width: '200px' }
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.asset.table.column.tooltip.serviceAccount" }) }

        }, {
            dataField: 'companyCity',
            text: <FormattedMessage id="app.asset.table.column.title.serviceAccountCity" />,
            headerStyle: (colum, colIndex) => {
                return { width: '130px' }
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.asset.table.column.tooltip.serviceAccountCity" }) }

        }, {
            dataField: 'status',
            text: <FormattedMessage id="app.asset.table.column.title.status" />,
            headerStyle: (colum, colIndex) => {
                return { width: '75px' }
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.asset.table.column.tooltip.status" }) }

        }

        ];

        return (
            <>
                <div className="search-results">
                    <BootstrapTable
                        striped
                        hover
                        keyField='Name'
                        columns={columns}
                        data={this.props.data.records}
                        headerClasses="thead-dark"
                        noDataIndication={(<FormattedMessage id='app.grid.noRecordsFound' />)}
                    />
                </div>
            </>
        );


    }
}
export default injectIntl(AssetTable);