import React from "react";
import { BrowserRouter, Route } from 'react-router-dom'
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import Login from './components/login/Login';
import { Provider } from "react-redux";
import store from "./common/Store";
import { uuid } from 'uuidv4';
import Loading from "./components/loading/Loading";
import ReadOnlyPopup from "./components/common/ReadOnlyPopup";
import NotificationDialog from "./components/common/NotificationDialog";
import AssetDetailPage from "./components/asset/detail/AssetDetailPage";
import AssetPage from "./components/asset/main/AssetPage";
import ActivityLogPage from "./components/activityLog/ActivityLogPage";
import ImplicitLoginCallback from "./components/login/ImplicitCallback";
import { FormattedMessage } from "react-intl";
const config = require('../config/config_' + ((process.env.REACT_APP_STAGE) ? process.env.REACT_APP_STAGE : 'prod') + '.json');

export default (class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sessionId: uuid()
    };
  }
   
  render() {

    return (
      <div className="app">
        <BrowserRouter >
          <Provider store={store} className="App">
            <Loading />
            <Security {...config.Okta}
              redirectUri={window.location.origin + '/implicit/callback'}>              
              <SecureRoute path='/' exact={true} component={() => <AssetPage />} />
              <Route path='/implicit/callback' component={ImplicitLoginCallback} />
              <Route path='/Login' component={Login} />
              <Route path='/Details' component={AssetDetailPage} />
              <Route path='/ActivityLog' component={ActivityLogPage} />
              <Route path='/ReLogin' component={() => <Login errorMessage={<FormattedMessage id="app.login.login.relogin" />} />} />
            </Security>
            <ReadOnlyPopup />
            <NotificationDialog />
          </Provider>
        </BrowserRouter>
      </div>
    );
  }
});
