import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import AssetTable from './AssetTable';
import AssetSSHKeyTable from './AssetSSHKeyTable';
import ActionButton from './ActionButton';
import { Link } from 'react-router-dom';
import * as Actions from '../main/AssetAction'
import { connect } from "react-redux";
import DownloadKeyModalContent from './DownloadKeyModalContent';
import { Action } from '../../../common/ActionType';
import {
  FormFieldDefinitions, FormTypeGenerateKey, FormTypeDownloadKey,
  FormTypeActivateKey, FormTypeRetireKey, FormTypeUnRetireKey
} from './FormConstant';
import ReasonModal from './ReasonModal';
import { UserService } from '../../../common/UserService';
import { VaultRights, securityRight } from '../../../common/SecurityRight'

export default withOktaAuth(connect()(class AssetDetailPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      alertModalData: {},
      buttonVisibleMap: {},
      asset: {}
    };
    this.handleOnGenerateClick = this.handleOnGenerateClick.bind(this);
    this.handleOnActivateClick = this.handleOnActivateClick.bind(this);
    this.handleOnDownloadClick = this.handleOnDownloadClick.bind(this);
    this.handleOnRetireClick = this.handleOnRetireClick.bind(this);
    this.handleOnUnRetireClick = this.handleOnUnRetireClick.bind(this);
    this.handleAlertModalFormChange = this.handleAlertModalFormChange.bind(this);
  }


  handleAlertModalFormChange = (event) => {
    let alertModalData = { ...this.state.alertModalData, ...event };
    this.setState({ alertModalData: alertModalData })
  }

  handleOnKeySelected = (row) => {
    const selectedKey = this.state.keysResults.records.find(key => key.id == row.id);
    this.setState({ selectedKey: selectedKey })

  }
  //Have to implement RefreshToken() method for Amplify to use,
  //to refresh token with Okta, but Okta SDK doesnt provide an API to refresh token
  componentDidMount() {

    const locationState = (this.props.location && this.props.location.state) || {}

    const selectedFields = [
      FormFieldDefinitions.instance.id,
      FormFieldDefinitions.marketingName.id,
      FormFieldDefinitions.companyName.id,
      FormFieldDefinitions.serialNumber.id,
      FormFieldDefinitions.siteNumber.id]
    let selectedAsset = {};
    selectedFields.forEach(field => {
      selectedAsset[field] = locationState['data'][field];
    })

    this.setState({ asset: selectedAsset });
    UserService.getLoginInfo().then(res => {
      const buttonVisibleMap = this.showHideButtons(res, selectedAsset.instance);
      this.setState({ userInfo: res, buttonVisibleMap: buttonVisibleMap });
    })

    this.getKeys(selectedAsset);

  }

  getKeys = (asset) => {
    return Actions.getKeys(asset).then(res => {
      this.setState({ keysResults: res })
      return res;
    })
  }
  refreshSelectedKey = () => {
    const selectedKey = this.state.keysResults.records.find(key => key.id === this.state.selectedKey.id);
    this.setState({ selectedKey: selectedKey })
  }


  showAlertModal = (formType, callbackFn) => {
    this.props.dispatch({
      type: Action.ACTION_SHOW_REASON_MODAL,
      formType: formType,
      callback: callbackFn
    });

  }
  buildRequestBody = () => {

    let requestBody = { ...this.state.asset };
    requestBody.reason = this.state.alertModalData.reason;
    requestBody.reason_detail = this.state.alertModalData.reasonDetail;
    requestBody.version = this.state.selectedKey.version;
    requestBody.createdDate = this.state.selectedKey.created;
    return requestBody;
  }

  handleOnGenerateClick = (e) => {
    this.showAlertModal(FormTypeGenerateKey, this.generateKey)
  }
  generateKey = () => {
    let requestBody = { ...this.state.asset };
    requestBody.reason = this.state.alertModalData.reason;
    requestBody.reason_detail = this.state.alertModalData.reasonDetail;
    Actions.generatekey(requestBody).then(res => {
      if (res && res.status) {
        this.getKeys(this.state.asset).then(() => {
          //get latest generated records and make it selected
          const selectedKey = this.state.keysResults && this.state.keysResults.records &&
            this.state.keysResults.records.length > 0 ? this.state.keysResults.records[this.state.keysResults.records.length - 1] : undefined;
          this.setState({ selectedKey: selectedKey })
          this.downloadKey();
        })
      }
    })
  }

  handleOnActivateClick = (e) => {
    this.showAlertModal(FormTypeActivateKey, this.activateKey)

  }
  activateKey = () => {
    Actions.activateKey(this.buildRequestBody()).then(() => {
      this.getKeys(this.state.asset).then(res => {
        this.refreshSelectedKey();
      })
    })
  }

  downloadSSHKey = (e) => {
    let requestBody = this.buildRequestBody();
    Actions.getPublicKeyPackage(requestBody).then(() => {
      this.getKeys(this.state.asset).then(res => {
        this.refreshSelectedKey();
      })
    })
  }
  downloadPrivateKey = (e) => {
    let requestBody = this.buildRequestBody();
    Actions.getPrivateKeyPackage(requestBody).then(() => {
      this.getKeys(this.state.asset).then(res => {
        this.refreshSelectedKey();
      })
    })
  }

  handleOnDownloadClick = (e) => {
    this.showAlertModal(FormTypeDownloadKey, this.downloadKey)
  }

  downloadKey = () => {
    this.props.dispatch({
      type: Action.ACTION_SHOW_INFO,
      buttons: [{ text: "CLOSE", callback: () => { }, isPrimary: true }],
      body: <DownloadKeyModalContent
        onDownloadSSHKey={this.downloadSSHKey}
        onDownloadPrivateKey={this.downloadPrivateKey}
        buttonVisibleMap={this.state.buttonVisibleMap}

      />,
    });
  }


  handleOnRetireClick = (e) => {
    this.showAlertModal(FormTypeRetireKey, this.retireKey)
  }

  retireKey = () => {
    Actions.retireKey(this.buildRequestBody()).then(() => {
      this.getKeys(this.state.asset).then(res => {
        this.refreshSelectedKey();
      })

    })
  }

  handleOnUnRetireClick = (e) => {
    this.showAlertModal(FormTypeUnRetireKey, this.unRetireKey)
  }
  unRetireKey = () => {
    Actions.unretirekey(this.buildRequestBody()).then(() => {
      this.getKeys(this.state.asset).then(res => {
        this.refreshSelectedKey();
      })
    })
  }

  showHideButtons = (userInfo, instance) => {
    const securityRights = securityRight(userInfo.userRights);
    const buttonVisibleMap = {}
    buttonVisibleMap.showActivityLog = securityRights.can(instance, VaultRights.ViewLogByKey);
    return buttonVisibleMap;
  }

  render() {
    const data = { records: [this.props.location.state.data] }

    return (
      <div className="col-12">

        <div className="mb-1">
          <Link className="btn btn-secondary" to={{ pathname: "/" }}>Back</Link>
          {this.state.buttonVisibleMap.showActivityLog
            && <Link className="btn btn-secondary float-right" to={{
              pathname: "/ActivityLog",
              state: { data: { ...this.state.asset } }
            }}>Activity Log</Link>
          }

        </div>
        <AssetTable data={data} />

        <div className="col-xs-12 col-md-10 offset-md-1 mb-3">
          {this.state.keysResults && this.state.keysResults.records &&
            <AssetSSHKeyTable
              data={this.state.keysResults}
              onKeySelected={this.handleOnKeySelected}
              selectedKey={this.state.selectedKey}
            />
          }
          <ActionButton
            sshKey={this.state.selectedKey}
            onGenerateClick={this.handleOnGenerateClick}
            onActivateClick={this.handleOnActivateClick}
            onDownloadClick={this.handleOnDownloadClick}
            onRetireClick={this.handleOnRetireClick}
            onUnRetireClick={this.handleOnUnRetireClick}
            userInfo={this.state.userInfo}
            instance={this.state.asset.instance}
          />
        </div>
        <ReasonModal
          onChange={(event) => this.handleAlertModalFormChange(event)}
        />
      </div>

    );
  }
}));