import React from 'react';
import GenericPage from '../../layout/GenericPage';
import AssetDetailContent from './AssetDetailContent';


export default class AssetDetailPage extends React.Component {

    render() {
        return (
            <GenericPage render={() => <AssetDetailContent {...this.props}/>} pageTitle="Asset Detail"/>
        );
    }
}