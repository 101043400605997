import React from 'react';
import PropTypes from 'prop-types';
import FormBase from '../../common/FormBase';
export default class AssetSearchForm extends React.Component {
  static propTypes = {
    formInputs: PropTypes.object,
    formType: PropTypes.string,
    onChange: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.handleOnInput = this.handleOnInput.bind(this)
  }

  handleOnInput = (e) => {
    e.formType = this.props.formType;
    this.props.onChange(e);
  }

  render() {
    return (
      <FormBase onInput={this.handleOnInput} {...this.props} />
    );
  }
}