import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';

const LoginError = (props) => {

    return (
        <Redirect to={{
            pathname: '/Login',
            state: { ...props }
        }}
        />
    );
};

export default LoginError;