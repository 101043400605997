import React from 'react';
import PropTypes from 'prop-types';
import "react-datepicker/dist/react-datepicker.css";
import CustomDatePicker from './CustomDatePicker';
import CustomLabel from './CustomLabel';

export default class LabeledInput extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    id: PropTypes.any,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    tooltip: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.array,
    dropdowns: PropTypes.array,
    onInput: PropTypes.func
  }
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.onInput(event);
  }


  render() {
    let { id, label, tooltip, placeholder, options } = this.props;
    let selectedOption = options && options.find(o => o.selected === true)

    let rowClassName = this.props.required ? "row form-group required" : "row form-group";

    let labelClassName = this.props.labelClassName ? "control-label pt-2 ".concat(this.props.labelClassName) : "col-xs-12 col-md-4 control-label pt-2"



    return (
      !(this.props.visible === false) && <div className={this.props.containerClassName || "col-xs-12 col-lg-6"} >
        <div className={rowClassName}>

          <CustomLabel
            id={id}
            label={label}
            tooltip={tooltip}
            labelClassName={labelClassName}
          />

          <div className={this.props.inputClassName || "col-xs-12 col-md-8"}>
            {this.props.type === 'selection' &&
              <select className="form-input"
                id={id} name={id}
                onChange={this.handleChange}
                value={this.props.value}
                disabled={this.props.disabled}
              >
                {!selectedOption && <option value="">Select option</option>}
                {
                  (options || []).map(option => {
                    return <option value={option.value} key={option.name}>{option.name}</option>
                  })
                }
              </select>
            }
            {this.props.type === 'text' &&
              <input
                type="text"
                className="form-input"
                id={id}
                name={id}
                placeholder={placeholder}
                value={this.props.value}
                onChange={this.handleChange}
                disabled={this.props.disabled}
              />
            }

            {this.props.type === 'textarea' &&
              <textarea
                className="form-control"
                id={id}
                name={id}
                placeholder={placeholder}
                onChange={this.handleChange}
                rows="2"
                value={this.props.value}
                disabled={this.props.disabled}
              />
            }
            {this.props.type === 'datetime' &&
              <CustomDatePicker
                className="form-control"
                id={id}
                name={id}
                placeholder={placeholder}
                onChange={this.handleChange}
                selected={this.props.value}
                dateFormat={this.props.dateFormat}
                disabled={this.props.disabled}
              />
            }

          </div>
        </div>
      </ div >
    );
  }
}