import React from 'react';
import Button from 'react-bootstrap/esm/Button';

export default class DownloadKeyModalContent extends React.Component {
  render() {
    return (
      <div className="animation-fade">
        <div className="row " >
          <div className="col-xs-12 col-md-6">
            <Button
              className="m-2 text-capitalize"
              name="downloadSSHKey"
              onClick={this.props.onDownloadSSHKey}>Download SSH Key Packages</Button>
          </div>

          <div className="col-xs-12 col-md-6">
            <Button
              className="m-2 text-capitalize"
              name="downloadPrivateKey"
              onClick={this.props.onDownloadPrivateKey}>Download Private Key Packages</Button>
          </div>
        </ div >
      </div>
    );
  }
}