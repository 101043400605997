import React from 'react';
import Logo from './Logo';
import Profile from './Profile';
import { FormattedMessage } from 'react-intl';

export default class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sessionId: this.props.sessionId,
      userName: this.props.userName,
      userEmail: this.props.userEmail,
      oktaGroup: this.props.oktaGroup,
      vaultRole: this.props.vaultRole

    };
  }

  handleShowAlert(options) {
    this.props.showAlertPopup(options);
  }
  render() {
    return (

      <header className="header container">
        <div className="navbar navbar-light bg-light shadow-sm">
          <div className="container d-flex justify-content-between pl-0">
            <div className="d-flex justify-content-start">
              <span><Logo /> </span>
              <h3 className="page-title">{<FormattedMessage id="app.name" />}{this.props.pageTitle && (" - " + this.props.pageTitle)}</h3>
            </div>
            <Profile />
          </div>
        </div>
      </header>
    );
  }
}


