export const Action = {
  /* COMMON ----- start ------ */
  ACTION_READ_NOTIFICATIONS: "ACTION_READ_NOTIFICATIONS",
  ACTION_SAVE_NOTIFICATIONS_CONFIG: "ACTION_SAVE_NOTIFICATIONS_CONFIG",
  ACTION_USER_LOGOUT: "ACTION_USER_LOGOUT",
  ACTION_USER_LOGOUT_AND_REDIRECT: "ACTION_USER_LOGOUT_AND_REDIRECT",
  ACTION_SAVE_NOTIFICATIONS_SETTINGS: "ACTION_SAVE_NOTIFICATIONS_SETTINGS",
  ACTION_SAVE_NOTIFICATIONS_FROM_SOCKETS: "ACTION_SAVE_NOTIFICATIONS_FROM_SOCKETS",
  ACTION_SAVE_NOTIFICATIONS_COUNT: "ACTION_SAVE_NOTIFICATIONS_COUNT",
  ACTION_CLEAR_NOTIFICATIONS: "ACTION_CLEAR_NOTIFICATIONS",
  ACTION_UPDATE_ICON_TYPE: "ACTION_UPDATE_ICON_TYPE",
  ACTION_LOADING: "ACTION_LOADING",
  ACTION_LOADED: "ACTION_LOADED",
  ACTION_SAVE_NOTIFICATION_ICONS: "ACTION_SAVE_NOTIFICATION_ICONS",
  ACTION_FILE_UPLOAD: "ACTION_FILE_UPLOAD",
  ACTION_ROLLER_LOADING: "ACTION_ROLLER_LOADING",
  ACTION_ROLLER_LOADED: "ACTION_ROLLER_LOADED",
  
  ACTION_SHOW_ERROR: "ACTION_SHOW_ERROR",
  ACTION_SHOW_ERROR_DETAIL: "ACTION_SHOW_ERROR_DETAIL",
  ACTION_SHOW_WARN: "ACTION_SHOW_WARN",
  ACTION_SHOW_INFO: "ACTION_SHOW_INFO",
  ACTION_DIALOG_HIDE: "ACTION_DIALOG_HIDE",

  ACTION_SHOW_POPUP: "ACTION_SHOW_POPUP",
  ACTION_HIDE_POPUP: "ACTION_HIDE_POPUP",

  ACTION_SHOW_CONFIRM: "ACTION_SHOW_CONFIRM",
  ACTION_HIDE_CONFIRM: "ACTION_HIDE_CONFIRM",

  ACTION_SHOW_OVERLAY: "ACTION_SHOW_OVERLAY",
  ACTION_HIDE_OVERLAY: "ACTION_HIDE_OVERLAY",
  /* COMMON ----- end ------ */

  ACTION_SHOW_REASON_MODAL: "ACTION_SHOW_REASON_MODAL",
  ACTION_HIDE_REASON_MODAL: "ACTION_HIDE_REASON_MODAL",

  ACTION_DENY_ERROR: "ACTION_DENY_ERROR",


};
