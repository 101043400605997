import React from 'react';
// import { Column, Table } from 'react-virtualized'
import { UserService } from '../../common/UserService';
import { connect } from "react-redux";
import ActivityLogSearchForm from './ActivityLogSearchForm';
import ActivityLogSearchResult from './ActivityLogSearchResult';
import { withOktaAuth } from '@okta/okta-react';
import { Button } from 'react-bootstrap';
export default withOktaAuth(connect()(class ActivityLogMain extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            searchResult: undefined,
            userInfo: undefined
        };
        this.activityLogSearchFormRef = React.createRef();
        this.handleOnReset = this.handleOnReset.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnBack = this.handleOnBack.bind(this);
        this.handleOnSearchMoreCallBack = this.handleOnSearchMoreCallBack.bind(this);
        this.handleOnSearchMore = this.handleOnSearchMore.bind(this);

    }

    componentDidMount() {
        const locationState = (this.props.location && this.props.location.state) || {}
        UserService.getLoginInfo().then(res => {
            this.setState({ data: locationState.data, userInfo: res });
        })
    }

    handleOnReset = (e) => {
        this.setState({ searchResult: undefined })
    }

    handleOnSearch = (e, data) => {
        const searchResult = {
            records: (data && data.records) || [],
            lastEvaluatedKey: (data && data.lastEvaluatedKey) || undefined
        }
        this.setState({ searchResult: searchResult })
    }


    handleOnBack = () => {
        this.props.history.goBack();
    }

    handleOnSearchMoreCallBack = (data) => {
        const existingRecords = this.state.searchResult.records || []
        const lastEvaluatedKey = data && data.lastEvaluatedKey || undefined
        const moreRecords = (data && data.records) || []

        const searchResult = {
            records: existingRecords.concat(moreRecords),
            lastEvaluatedKey: lastEvaluatedKey
        }
        this.setState({ searchResult: searchResult })
    }
    handleOnSearchMore = () => {
        this.activityLogSearchFormRef.current.searchMore();
    }

    render() {
        const data = { ...this.state.data, ...this.state.userInfo }

        return (

            <div className="col-12">
                <div className="mb-1">
                    <Button className="btn btn-secondary" onClick={this.handleOnBack}>Back</Button>
                </div>

                <ActivityLogSearchForm
                    ref={this.activityLogSearchFormRef}
                    onReset={this.handleOnReset}
                    onSearch={this.handleOnSearch}
                    onSearchMore={this.handleOnSearchMoreCallBack}
                    data={data}
                />

                {this.state.searchResult && this.state.searchResult.records &&
                    <ActivityLogSearchResult
                        data={this.state.searchResult}
                        onSearchMore={this.handleOnSearchMore}
                    />
                }

            </div >
        )
    }


}))

