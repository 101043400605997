import {
  makeDownloadRequest,
  httpPost,
  HttpMethod,
} from "../../../common/AjaxService";
const assetResponse = require('../../../constants/search.json');
// const keyResponse = require('../../../constants/keys.json');
export class AssetService {

  static async searchAsset(queryObj) {

    return await httpPost("/asset", queryObj, "json", true);
    // return assetResponse
  };

  static async getKeys(queryObj) {
    return await httpPost("/keys", queryObj, "json", true);

  }

  static async generateKey(queryObj) {
    return await httpPost("/generatekey", queryObj, "json", true);
  }

  static async activateKey(queryObj) {
    return await httpPost("/activatekey", queryObj, "json", true);

  }

  static async getPrivateKeyPackage(queryObj) {
    return await makeDownloadRequest("/getprivatekeypackage", HttpMethod.POST, queryObj);
  }

  static async getPublicKeyPackage(queryObj) {
    return await makeDownloadRequest("/getpublickeypackage", HttpMethod.POST, queryObj);
  }

  static async retireKey(queryObj) {
    return await httpPost("/retireKey", queryObj, "json", true);
  }

  static async unretirekey(queryObj) {
    return await httpPost("/unretirekey", queryObj, "json", true);
  }
}