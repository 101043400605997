import React from 'react';
import LabeledInput from './LabeledInput';

export default function FormBase(props) {

    const form = props.formInputs && props.formType && props.formInputs[props.formType];
    let inputs = undefined;
    if (form) {
        inputs = form.map(input => (
            <LabeledInput type={input.type}
                key={input.id}
                id={input.id}
                label={input.label}
                options={input.dropdowns}
                onInput={props.onInput}
                value={input.value || ""}
                required={input.required}
                containerClassName={input.containerClassName}
                labelClassName={input.labelClassName}
                inputClassName={input.inputClassName}
                visible={input.visible}
                dateFormat={input.dateFormat}
                disabled={input.disabled}
                tooltip={input.tooltip}
                placeholder={input.placeholder}
            />

        ));
    }

    return (
        <div className="animation-fade form-group row">
            {form && inputs}
        </div>
    );

}