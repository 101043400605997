export function initForm(fieldSpecArr) {
    let initializedForm = undefined;
    if (fieldSpecArr) {
        initializedForm = [];
        fieldSpecArr.forEach(fieldSpec => {
            const initFieldSpec = {
                ...fieldSpec
            };
            let defaultValue = undefined;
            switch (fieldSpec.type) {
                case 'selection': {
                    if (fieldSpec.dropdowns && fieldSpec.dropdowns.length > 0) {
                        const selectedOp = fieldSpec.dropdowns.find(op => op.selected == true);
                        if (selectedOp) {
                            defaultValue = selectedOp.value;
                        }
                    }
                    break;
                }
                default: {
                    defaultValue = fieldSpec.value;
                }
            }


            initFieldSpec.value = defaultValue;
            initializedForm.push(initFieldSpec)
        })
    }
    return initializedForm;
}

export function getFormField(formArr, formType, fieldId) {
    return formArr[formType].find(input => input.id === fieldId);
}

export function camalize(str) {
    return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

export function underscorize(str) {
    return str.replace(/([A-Z])/g, "_$1").toLowerCase();
}

export function trim(str) {
    return str ? str.toString().replace(/^\s+|\s+$/gm, '') : str;
}

export function underscorizeObj(obj) {
    const underscorizedObj = {};
    Object.keys(obj).forEach(key => {
        underscorizedObj[underscorize(key)] = obj[key];
    })
    return underscorizedObj;

}