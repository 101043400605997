import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { injectIntl, FormattedMessage } from 'react-intl';
import './ActivityLogSearchResult.scss'
import { Button } from 'react-bootstrap';
class ActivityLogSearchResult extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: {},
        };
        this.hanldeOnClick = this.hanldeOnClick.bind(this);
    }

    hanldeOnClick = (e) => {
        this.props.onSearchMore();

    }

    render() {
        const { formatMessage } = this.props.intl;
        const columns = [{
            dataField: 'id',
            text: 'Asset ID',
            hidden: true,
            // isDummyField: true
        }, {
            dataField: 'createdDate',
            text: <FormattedMessage id="app.activityLog.table.column.title.eventDate" />,
            headerStyle: (colum, colIndex) => {
                return { width: '100px' };
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.activityLog.table.column.tooltip.eventDate" }) }

        }, {
            dataField: 'event',
            text: <FormattedMessage id="app.activityLog.table.column.title.event" />,
            headerStyle: (colum, colIndex) => {
                return { width: '100px' };
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.activityLog.table.column.tooltip.event" }) }

        },
        {
            dataField: 'instance',
            text: <FormattedMessage id="app.activityLog.table.column.title.instance" />,
            headerStyle: (colum, colIndex) => {
                return { width: '100px' };
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.activityLog.table.column.tooltip.instance" }) }

        }, {
            dataField: 'companyName',
            text: <FormattedMessage id="app.activityLog.table.column.title.companyName" />,
            headerStyle: (colum, colIndex) => {
                return { width: '200px' };
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.activityLog.table.column.tooltip.companyName" }) }

        }, {
            dataField: 'marketingName',
            text: <FormattedMessage id="app.activityLog.table.column.title.marketingName" />,
            headerStyle: (colum, colIndex) => {
                return { width: '140px' };
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.activityLog.table.column.tooltip.marketingName" }) }

        }, {
            dataField: 'siteNumber',
            text: <FormattedMessage id="app.activityLog.table.column.title.siteNumber" />,
            headerStyle: (colum, colIndex) => {
                return { width: '80px' };
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.activityLog.table.column.tooltip.siteNumber" }) }

        }, {
            dataField: 'serialNumber',
            text: <FormattedMessage id="app.activityLog.table.column.title.serialNumber" />,
            headerStyle: (colum, colIndex) => {
                return { width: '80px' };
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.activityLog.table.column.tooltip.serialNumber" }) }

        }, {
            dataField: 'version',
            text: <FormattedMessage id="app.activityLog.table.column.title.version" />,
            headerStyle: (colum, colIndex) => {
                return { width: '65px' };
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.activityLog.table.column.tooltip.version" }) }

        }, {
            dataField: 'userId',
            text: <FormattedMessage id="app.activityLog.table.column.title.userId" />,
            headerStyle: (colum, colIndex) => {
                return { width: '100px' };
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.activityLog.table.column.tooltip.userId" }) }

        }, {
            dataField: 'reason',
            text: <FormattedMessage id="app.activityLog.table.column.title.reason" />,
            headerStyle: (colum, colIndex) => {
                return { width: '100px' };
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.activityLog.table.column.tooltip.reason" }) }

        }, {
            dataField: 'reasonDetail',
            text: <FormattedMessage id="app.activityLog.table.column.title.reasonDetail" />,
            headerStyle: (colum, colIndex) => {
                return { width: '100px' };
            },
            headerTitle: function callback(column, colIndex) { return formatMessage({ id: "app.activityLog.table.column.tooltip.reasonDetail" }) }

        }

        ];

        const options = {
            page: 1,
            sizePerPageList: [{
                text: '5', value: 5
            }, {
                text: '10', value: 10
            }, {
                text: 'All', value: this.props.data.length
            }],
            sizePerPage: 5,
            pageStartIndex: 1,
            paginationSize: 3,
            prePage: 'Prev',
            nextPage: 'Next',
            firstPage: 'First',
            lastPage: 'Last',
            paginationPosition: 'top'

        };

        return (
            <>
                <h4>Search Results</h4>
                <div className="activity-log-tbl">
                    <BootstrapTable
                        striped
                        hover
                        keyField='id'
                        columns={columns}
                        data={this.props.data.records}
                        headerClasses="thead-dark align-top"
                        noDataIndication={(<FormattedMessage id='app.grid.noRecordsFound' />)}
                    // pagination={paginationFactory(options)}
                    />
                </div>
                {this.props.data && this.props.data.lastEvaluatedKey &&
                    <div className="d-flex flex-row-reverse mt-4">
                        <Button variant="secondary" onClick={this.hanldeOnClick}>More</Button>
                    </div>
                }
            </>
        );


    }
}
export default injectIntl(ActivityLogSearchResult);