import React from 'react';


export default class Footer extends React.Component {
  render() {
    return (

      <footer>
        <img className="mx-auto footer-logo" src="./assets/images/hv.png" alt="HV logo" />
      </footer>
    );
  }
}