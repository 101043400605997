import React from 'react';
import Header from './Header';
import Footer from './Footer';

export default class GenericPage extends React.Component {


  render() {

    return (
      <div className="home-page__container container">

        {/* App Header */}
        <div className="home-page__header container">
          <div className="row">
            <Header  {...this.props} />
          </div>
        </div>
        {/* App Main Content */}

        <div className="home-page__main row">

          {this.props.render()}

        </div>
        {/* App footer */}
        <div className="home-page__footer row">
          <Footer />
        </div>
      </div>

    );
  }
}
