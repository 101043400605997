import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker'

export default class CustomDatePicker extends React.Component {
    static propTypes = {
        type: PropTypes.string,
        id: PropTypes.any,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.any,
        options: PropTypes.array
    }
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }
    //override parameters
    handleChange = (date, event) => {
        const customeEvent = { target: { name: this.props.name, value: date } }
        this.props.onChange(customeEvent);

    }

    //override parameters
    handleSelect = (date, event) => {

        const customeEvent = { target: { name: this.props.name, value: date } }
        this.props.onChange(customeEvent);
    }

    render() {
        return <DatePicker {...this.props} onChange={this.handleChange} onSelect={this.handleSelect} />
    }

}