export const VaultRights = {
    GenerateKey: 1,
    DLPublicPackage: 2,
    DLPrivatePackage: 4,
    Activate: 8,
    Retire: 16,
    Unretire: 32,
    ViewLogByKey: 64,
    AuditLog: 128
}

export function securityRight(rights) {
    return {
        _rights: rights || {},
        can: function (instance, right) {
            return (this._rights[instance] && ((this._rights[instance] & right) == right)) == true;
        },
        canAccess: function (instance) {
            return (this._rights[instance] && (this._rights[instance] > 0)) == true;
        }
    }
}